export const MAX_TOOLTIP_POSITION = 450;
export const NONE = 'None';

export const LIMITED_ACC_MESSAGES = {
    TOXIN_GROUPS:
        'Your account is limited to certain policies. In order to enable product vetting against additional ' +
        'standards and regulations, please send a request to upgrade your access.',
}

export const DEFAULT_RM_TABLE_LAYOUT = {
    functions: true,
    inci: true,
    manufacturer: true,
    price: true,
    reference_number: false,
    status: false,
    supplier: false,
    tags: false,
    trade_name: true,
    frequency_of_use: true,
}

export const RM_COLUMN_SWITCHER_NAMES_OLD = {
    trade_name: 'Trade Name',
    reference_number: "Reference #",
    status: 'Status',
    manufacturer: 'Manufacturer',
    supplier: 'Supplier',
    functions: 'Functions',
    inci: 'INCI',
    tags: 'Tags',
    price: 'Price',
}
export const RM_COLUMN_SWITCHER_NAMES = {
    trade_name: 'Trade Name',
    reference_number: "Reference #",
    status: 'Status',
    manufacturer: 'Manufacturer',
    supplier: 'Supplier',
    functions: 'Functions',
    frequency_of_use: 'Frequency',
    inci: 'INCI',
    tags: 'Tags',
    price: 'Price',
}

export const FILTERS_TYPES = {
    STATUS: 'Status',
    MANUFACTURERS: 'Manufacturers',
    // SUPPLIER: 'Supplier', <- future
    PRICE_KG: 'PriceKg',
    FUNCTIONS: 'Functions',
    APPLICATIONS: 'Applications',
    CERTIFICATIONS: 'Certifications',
    TAGS: 'Tags',
    NATURAL_CONTENT: 'NaturalContent',
    DOCUMENTS: 'Documents',
    COMPLIANCE: 'Compliance',
}

export const FILTERS_MAIN_TYPES = {
    MANUFACTURERS: FILTERS_TYPES.MANUFACTURERS,
    FUNCTIONS: FILTERS_TYPES.FUNCTIONS,
    COMPLIANCE: FILTERS_TYPES.COMPLIANCE,
}

export const FILTERS_MAIN_TYPES_OLD = {
    MANUFACTURERS: FILTERS_TYPES.MANUFACTURERS,
    FUNCTIONS: FILTERS_TYPES.FUNCTIONS,
    APPLICATIONS: FILTERS_TYPES.APPLICATIONS,
    CERTIFICATIONS: FILTERS_TYPES.CERTIFICATIONS,
    COMPLIANCE: FILTERS_TYPES.COMPLIANCE,
    STATUS: FILTERS_TYPES.STATUS,
}

export const FILTERS_GRADATION = {
    10: 10,
    20: 20,
    50: 50,
    90: 90,
    100: 100,
    MORE: 'more',
    LESS: 'less',
    RANGE: 'range',
}

export const FILTERS_MEASURE = {
    PRICE_KG: '$',
    NATURAL_CONTENT: '%',
}

export const FILTERS_TITLES = {
    [FILTERS_TYPES.MANUFACTURERS]: 'Manufacturers',
    [FILTERS_TYPES.FUNCTIONS]: 'Functions',
    [FILTERS_TYPES.COMPLIANCE]: 'Compliance',
    [FILTERS_TYPES.APPLICATIONS]: 'Applications',
    [FILTERS_TYPES.CERTIFICATIONS]: 'Certifications',
    [FILTERS_TYPES.TAGS]: 'Tags',
    [FILTERS_TYPES.DOCUMENTS]: 'Documents',
    [FILTERS_TYPES.PRICE_KG]: 'Price per kg',
    [FILTERS_TYPES.NATURAL_CONTENT]: 'Natural Content',
    [FILTERS_TYPES.STATUS]: 'Status',
    SEE_MORE: 'See More',
    SEE_LESS: 'See Less',
    MAIN: {
        ALL_FILTERS: 'All Filters',
        SELECTED_FILTERS: 'Selected Filters',
    },
    BUTTONS: {
        SCREEN: 'Screen',
        APPLY: 'Apply',
        APPLY_SELECTED: 'Apply Selected',
        CLEAR: 'Clear',
        CLEAR_ALL: 'Clear All',
        MORE_FILTERS: 'More Filters',
        [FILTERS_TYPES.PRICE_KG]: 'Less than',
        [FILTERS_TYPES.NATURAL_CONTENT]:' More than',
        [FILTERS_GRADATION.RANGE]: 'Range',
    }
}

export const TOOLTIPS_TITLES = {
    publishedTnl: 'Trade Name Library - ',
};
